var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
    _vm.serviceRequest
      ? _c("div", [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "jumbotron m-0 text-dark bg-white border-white border"
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass: "text-center display-5 text-uppercase mb-2"
                      },
                      [_vm._v(" Richiesta Servizio - Dettaglio ")]
                    ),
                    _c(
                      "b-form",
                      {
                        on: {
                          submit: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.onSubmit($event)
                          }
                        }
                      },
                      [
                        _c(
                          "b-form-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { lg: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "family_user-input-group",
                                      label: _vm.$t("services.family_user"),
                                      "label-for": "family_user"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "family_user",
                                        name: "family_user",
                                        type: "text",
                                        readonly: "",
                                        value: _vm.familyUserName
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { lg: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "family_phone_number-input-group",
                                      label: _vm.$t("register.phone"),
                                      "label-for": "family_phone_number"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "family_phone_number",
                                        name: "family_phone_number",
                                        type: "text",
                                        readonly: "",
                                        value:
                                          _vm.serviceRequest &&
                                          _vm.serviceRequest.family_user
                                            ? _vm.serviceRequest.family_user
                                                .phone_number
                                            : ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-form-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { lg: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "assisted_name-input-group",
                                      label: _vm.$t("services.assisted_name"),
                                      "label-for": "assisted_name"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "assisted_name",
                                        name: "assisted_name",
                                        type: "text",
                                        readonly: !_vm.canEdit,
                                        state: _vm.validateState(
                                          "assisted_name"
                                        ),
                                        "aria-describedby":
                                          "assisted_name-live-feedback"
                                      },
                                      model: {
                                        value: _vm.$v.form.assisted_name.$model,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$v.form.assisted_name,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "$v.form.assisted_name.$model"
                                      }
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        attrs: {
                                          id: "assisted_name-live-feedback"
                                        }
                                      },
                                      [
                                        !_vm.$v.form.assisted_name.required
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "validation.required"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { lg: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "address-input-group",
                                      label: _vm.$t("services.address"),
                                      "label-for": "address"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "address",
                                        name: "address",
                                        type: "text",
                                        readonly: !_vm.canEdit,
                                        state: _vm.validateState("address"),
                                        "aria-describedby":
                                          "address-live-feedback"
                                      },
                                      model: {
                                        value: _vm.$v.form.address.$model,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$v.form.address,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression: "$v.form.address.$model"
                                      }
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        attrs: { id: "address-live-feedback" }
                                      },
                                      [
                                        !_vm.$v.form.address.required
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "validation.required"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { lg: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "process_status-input-group",
                                      label: _vm.$t("services.process_status"),
                                      "label-for": "process_status"
                                    }
                                  },
                                  [
                                    _c(
                                      "multiselect",
                                      {
                                        class: {
                                          "is-invalid":
                                            _vm.$v.form.process_status.$error
                                        },
                                        attrs: {
                                          id: "process_status",
                                          disabled: !_vm.canEdit,
                                          "close-on-select": true,
                                          "clear-on-select": false,
                                          multiple: false,
                                          placeholder: _vm.$t(
                                            "select_placeholder"
                                          ),
                                          "select-label": _vm.$t(
                                            "multiselect.selectLabel"
                                          ),
                                          "selected-label": _vm.$t(
                                            "multiselect.selectedLabel"
                                          ),
                                          "deselect-label": _vm.$t(
                                            "multiselect.deselectLabel"
                                          ),
                                          options: _vm.processStatuses,
                                          object: false,
                                          state: _vm.validateState(
                                            "process_status"
                                          ),
                                          "aria-describedby":
                                            "process_status-live-feedback"
                                        },
                                        on: {
                                          select: function($event) {
                                            return _vm.test($event)
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "option",
                                              fn: function(ref) {
                                                var option = ref.option
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.statusLabel(option)
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "singleLabel",
                                              fn: function(ref) {
                                                var option = ref.option
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.statusLabel(option)
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3190223094
                                        ),
                                        model: {
                                          value:
                                            _vm.$v.form.process_status.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.form.process_status,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.form.process_status.$model"
                                        }
                                      },
                                      [
                                        _c("template", { slot: "noOptions" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "multiselect.noOptionsLabel"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("template", { slot: "noResult" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "multiselect.noResultLabel"
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ],
                                      2
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        attrs: {
                                          id: "process_status-live-feedback"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("validation.required")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.$loggedUser().is_affiliato
                              ? _c(
                                  "b-col",
                                  { attrs: { lg: "6" } },
                                  [
                                    _c(
                                      "b-overlay",
                                      {
                                        attrs: {
                                          show: _vm.loadingCaretakers,
                                          rounded: "sm"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              id: "caretaker_users-input-group",
                                              label: _vm.$t(
                                                "services.list_badanti"
                                              ),
                                              "label-for": "caretaker_users"
                                            }
                                          },
                                          [
                                            _c(
                                              "multiselect",
                                              {
                                                class: {
                                                  "is-invalid":
                                                    _vm.$v.form.caretaker_users
                                                      .$error
                                                },
                                                attrs: {
                                                  id: "caretaker_users",
                                                  disabled: this.disabilitato,
                                                  "close-on-select": true,
                                                  "clear-on-select": false,
                                                  multiple: true,
                                                  placeholder: _vm.$t(
                                                    "select_placeholder"
                                                  ),
                                                  "select-label": _vm.$t(
                                                    "multiselect.selectLabel"
                                                  ),
                                                  "selected-label": _vm.$t(
                                                    "multiselect.selectedLabel"
                                                  ),
                                                  "deselect-label": _vm.$t(
                                                    "multiselect.deselectLabel"
                                                  ),
                                                  options: _vm.caretakers,
                                                  object: false,
                                                  searchable: true,
                                                  "filter-results": true,
                                                  "min-chars": 1,
                                                  "track-by": "last_name",
                                                  label: "last_name",
                                                  "hide-selected": true,
                                                  state: _vm.validateState(
                                                    "caretaker_users"
                                                  ),
                                                  "aria-describedby":
                                                    "caretaker_users-live-feedback"
                                                },
                                                on: {
                                                  input: _vm.onCaretakerChange
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "option",
                                                      fn: function(ref) {
                                                        var option = ref.option
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                option.first_name
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                option.last_name
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "singleLabel",
                                                      fn: function(ref) {
                                                        var option = ref.option
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                option.first_name
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                option.last_name
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  4123988438
                                                ),
                                                model: {
                                                  value:
                                                    _vm.$v.form.caretaker_users
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form
                                                        .caretaker_users,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.caretaker_users.$model"
                                                }
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "noOptions" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "multiselect.noOptionsLabel"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "template",
                                                  { slot: "noResult" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "multiselect.noResultLabel"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              2
                                            ),
                                            _c(
                                              "b-form-invalid-feedback",
                                              {
                                                attrs: {
                                                  id:
                                                    "caretaker_users-live-feedback"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "validation.required"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { lg: "6" } }, [
                              _vm.caretakerUsers.length
                                ? _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("hr", {
                                          staticClass: "hr-text",
                                          attrs: {
                                            "data-content": "Lista Operatori"
                                          }
                                        })
                                      ]),
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "display-6 text-center"
                                        },
                                        [_vm._v(" Lista Operatori ")]
                                      ),
                                      _vm._l(_vm.caretakerUsers, function(
                                        item
                                      ) {
                                        return _c(
                                          "div",
                                          { key: item.id },
                                          [
                                            _c(
                                              "b-form-row",
                                              {
                                                staticClass:
                                                  "justify-content-center"
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { lg: "6" } },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        id:
                                                          "prezzo_badante_babysitter",
                                                        name:
                                                          "prezzo_badante_babysitter",
                                                        readonly: "",
                                                        "aria-describedby":
                                                          "prezzo_badante_babysitter-live-feedback",
                                                        value:
                                                          item.first_name +
                                                          " " +
                                                          item.last_name
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c("br")
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]),
                            _c("b-col", { attrs: { lg: "6" } }, [
                              _vm.serviceRequest
                                ? _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("hr", {
                                          staticClass: "hr-text",
                                          attrs: {
                                            "data-content": "Lista Servizi"
                                          }
                                        })
                                      ]),
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "display-6 text-center"
                                        },
                                        [_vm._v(" Lista Servizi ")]
                                      ),
                                      _vm._l(
                                        _vm.serviceRequest
                                          .associazionerichiestaservizio,
                                        function(item) {
                                          return _c(
                                            "div",
                                            { key: item.id },
                                            [
                                              _c(
                                                "b-form-row",
                                                {
                                                  staticClass:
                                                    "justify-content-center"
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { lg: "6" } },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "servizio",
                                                          name: "servizio",
                                                          readonly: "",
                                                          "aria-describedby":
                                                            "servizio-live-feedback",
                                                          value:
                                                            item.servizio.name +
                                                            " (x" +
                                                            item.quantita +
                                                            ")"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("br")
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-2 text-right" },
                          [
                            _c(
                              "b-button",
                              { attrs: { type: "submit", variant: "success" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(_vm.canEdit ? "update" : "back")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }