<template>
  <b-overlay :show="loading" rounded="sm">
    <div v-if="serviceRequest">
      <div class="container">
        <div class="row">
          <!-- Service description -->
          <div class="col-md-12">
            <div class="jumbotron m-0 text-dark bg-white border-white border">
              <h2 class="text-center display-5 text-uppercase mb-2">
                Richiesta Servizio - Dettaglio
              </h2>
              <b-form @submit.stop.prevent="onSubmit">
                <!-- Family User + Phone Number -->
                <b-form-row>
                  <b-col lg="6">
                    <b-form-group
                      id="family_user-input-group"
                      :label="$t('services.family_user')"
                      label-for="family_user"
                    >
                      <b-form-input
                        id="family_user"
                        name="family_user"
                        type="text"
                        readonly
                        :value="familyUserName"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group
                      id="family_phone_number-input-group"
                      :label="$t('register.phone')"
                      label-for="family_phone_number"
                    >
                      <b-form-input
                        id="family_phone_number"
                        name="family_phone_number"
                        type="text"
                        readonly
                        :value="
                          serviceRequest && serviceRequest.family_user
                            ? serviceRequest.family_user.phone_number
                            : ''
                        "
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <!-- Assisted Name + Status -->
                <b-form-row>
                  <b-col lg="6">
                    <b-form-group
                      id="assisted_name-input-group"
                      :label="$t('services.assisted_name')"
                      label-for="assisted_name"
                    >
                      <b-form-input
                        id="assisted_name"
                        name="assisted_name"
                        type="text"
                        :readonly="!canEdit"
                        v-model="$v.form.assisted_name.$model"
                        :state="validateState('assisted_name')"
                        aria-describedby="assisted_name-live-feedback"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback id="assisted_name-live-feedback">
                        <div v-if="!$v.form.assisted_name.required">
                          {{ $t("validation.required") }}
                        </div>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group
                      id="address-input-group"
                      :label="$t('services.address')"
                      label-for="address"
                    >
                      <b-form-input
                        id="address"
                        name="address"
                        type="text"
                        :readonly="!canEdit"
                        v-model="$v.form.address.$model"
                        :state="validateState('address')"
                        aria-describedby="address-live-feedback"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback id="address-live-feedback">
                        <div v-if="!$v.form.address.required">
                          {{ $t("validation.required") }}
                        </div>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group
                      id="process_status-input-group"
                      :label="$t('services.process_status')"
                      label-for="process_status"
                    >
                      <multiselect
                        id="process_status"
                        :disabled="!canEdit"
                        :class="{ 'is-invalid': $v.form.process_status.$error }"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :multiple="false"
                        :placeholder="$t('select_placeholder')"
                        :select-label="$t('multiselect.selectLabel')"
                        :selected-label="$t('multiselect.selectedLabel')"
                        :deselect-label="$t('multiselect.deselectLabel')"
                        :options="processStatuses"
                        :object="false"
                        v-model="$v.form.process_status.$model"
                        :state="validateState('process_status')"
                        aria-describedby="process_status-live-feedback"
                        @select="test($event)"
                      >
                        <template slot="option" slot-scope="{ option }">
                          {{ statusLabel(option) }}
                        </template>

                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ statusLabel(option) }}
                        </template>

                        <template slot="noOptions">
                          {{ $t("multiselect.noOptionsLabel") }}
                        </template>
                        <template slot="noResult">
                          {{ $t("multiselect.noResultLabel") }}
                        </template>
                      </multiselect>
                      <b-form-invalid-feedback
                        id="process_status-live-feedback"
                      >
                        {{ $t("validation.required") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <!-- Lista badanti -->
                  <b-col lg="6" v-if="$loggedUser().is_affiliato">
                    <b-overlay :show="loadingCaretakers" rounded="sm">
                      <b-form-group
                        id="caretaker_users-input-group"
                        :label="$t('services.list_badanti')"
                        label-for="caretaker_users"
                      >
                        <multiselect
                          id="caretaker_users"
                          :disabled="this.disabilitato"
                          :class="{
                            'is-invalid': $v.form.caretaker_users.$error,
                          }"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :multiple="true"
                          :placeholder="$t('select_placeholder')"
                          :select-label="$t('multiselect.selectLabel')"
                          :selected-label="$t('multiselect.selectedLabel')"
                          :deselect-label="$t('multiselect.deselectLabel')"
                          :options="caretakers"
                          :object="false"
                          :searchable="true"
                          :filter-results="true"
                          :min-chars="1"
                          track-by="last_name"
                          label="last_name"
                          v-model="$v.form.caretaker_users.$model"
                          :hide-selected="true"
                          :state="validateState('caretaker_users')"
                          aria-describedby="caretaker_users-live-feedback"
                          @input="onCaretakerChange"
                        >
                          <template slot="option" slot-scope="{ option }">
                            {{ option.first_name }} {{ option.last_name }}
                          </template>

                          <template slot="singleLabel" slot-scope="{ option }">
                            {{ option.first_name }} {{ option.last_name }}
                          </template>

                          <template slot="noOptions">
                            {{ $t("multiselect.noOptionsLabel") }}
                          </template>
                          <template slot="noResult">
                            {{ $t("multiselect.noResultLabel") }}
                          </template>
                        </multiselect>
                        <b-form-invalid-feedback
                          id="caretaker_users-live-feedback"
                        >
                          {{ $t("validation.required") }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-overlay>
                  </b-col>
                </b-form-row>
                <b-row>
                  <b-col lg="6">
                    <div class="mb-4" v-if="caretakerUsers.length">
                      <!-- lista badanti -->
                      <div class="mb-4">
                        <hr data-content="Lista Operatori" class="hr-text" />
                      </div>
                      <h4 class="display-6 text-center">
                        Lista Operatori
                      </h4>
                      <div v-for="item in caretakerUsers" :key="item.id">
                        <b-form-row class="justify-content-center">
                          <b-col lg="6">
                            <b-form-input
                              id="prezzo_badante_babysitter"
                              name="prezzo_badante_babysitter"
                              readonly
                              aria-describedby="prezzo_badante_babysitter-live-feedback"
                              :value="item.first_name + ' ' + item.last_name"
                            >
                            </b-form-input>
                          </b-col>
                        </b-form-row>
                        <br>
                      </div>
                    </div>
                  </b-col>
                  <b-col lg="6">
                    <div class="mb-4" v-if="serviceRequest">
                      <!-- lista servizi -->
                      <div class="mb-4">
                        <hr data-content="Lista Servizi" class="hr-text" />
                      </div>
                      <h4 class="display-6 text-center">
                        Lista Servizi
                      </h4>
                      <div v-for="item in serviceRequest.associazionerichiestaservizio" :key="item.id">
                        <b-form-row class="justify-content-center">
                          <b-col lg="6">
                            <b-form-input
                              id="servizio"
                              name="servizio"
                              readonly
                              aria-describedby="servizio-live-feedback"
                              :value="item.servizio.name + ' (x' + item.quantita + ')'"
                            >
                            </b-form-input>
                          </b-col>
                        </b-form-row>
                        <br>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <div class="mb-2 text-right">
                  <!-- Actions -->
                  <b-button type="submit" variant="success">
                    {{ $t(canEdit ? "update" : "back") }}
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import i18n from "@/i18n";
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { processStatuses } from "@/services/endpoints";
import router from "@/router";

export default {
  name: "ServiceDetailRequest",
  mixins: [validationMixin],
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.service_requests"),
    meta: [{ name: "description", content: i18n.t("menu.service_requests") }],
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        caretaker_users: [],
      },
      caretakerUsers: [],
      processStatuses: [
        processStatuses.notProcessed,
        processStatuses.confirmed,
        processStatuses.rejected,
        processStatuses.completed,
      ],
      disabilitato: true,
    };
  },
  watch: {
    serviceRequest: {
      handler(newValue) {
        this.form = newValue;
        if (newValue.city && newValue.city.id) {
          this.loadCaretakersByCity(newValue.city.id);
        }
        if (this.form.process_status === processStatuses.confirmed) {
          this.disabilitato = false;
        }
        // Load caretakers from local storage if available
        const storedCaretakers = localStorage.getItem(`caretaker_users_${this.id}`);
        if (storedCaretakers) {
          this.caretakerUsers = JSON.parse(storedCaretakers);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      loading: "serviceRequests/loadingServiceRequested",
      serviceRequest: "serviceRequests/serviceRequested",
      loadingCaretakers: "serviceRequests/loadingCaretakersByCity",
      caretakers: "serviceRequests/caretakersByCity",
    }),
    familyUserName() {
      return this.serviceRequest && this.serviceRequest.family_user
        ? `${this.serviceRequest.family_user.first_name} ${this.serviceRequest.family_user.last_name}`
        : "";
    },
  },
  validations: {
    form: {
      assisted_name: {
        required,
      },
      address: {
        required,
      },
      process_status: {
        required,
      },
      caretaker_users: {},
    },
  },
  methods: {
    ...mapActions({
      loadServiceRequest: "serviceRequests/getServiceRequestById",
      loadCaretakersByCity: "serviceRequests/getCaretakersByCity",
      updateServiceRequest: "serviceRequests/updateServiceRequest",
    }),
    test(event) {
      if (event === processStatuses.confirmed) {
        this.disabilitato = false;
      } else {
        this.disabilitato = true;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    statusLabel(status) {
      switch (parseInt(status)) {
        case processStatuses.notProcessed:
          return "Non elaborato";
        case processStatuses.confirmed:
          return "Confermato";
        case processStatuses.rejected:
          return "Annullato";
        case processStatuses.paid:
          return "Pagato";
        case processStatuses.completed:
          return "Completato";
        default:
          return "N/A";
      }
    },
    onCaretakerChange(value) {
      localStorage.setItem(`caretaker_users_${this.id}`, JSON.stringify(value));
    },
    async onSubmit() {
      if (this.canEdit) {
        await this.updateServiceRequest({
          ...this.form,
          caretaker_users: this.caretakerUsers.map(caretaker => caretaker.id), // Use caretaker IDs
        });
      }
      this.goToList();
    },
    goToList() {
      router.push({ name: "service_requests" });
    },
  },
  mounted() {
    if (!this.id) {
      this.goToList();
    } else {
      this.loadCaretakersByCity(1);
      this.loadServiceRequest(this.id);
    }
  },
};
</script>
